import React from "react";
import { useEffect,useState } from "react";
import { compareAsc, format,intervalToDuration } from 'date-fns'
import "./showTime.css"

export default function Time () {
   

    const [time, setTime] = useState(new Date());
    const dates = [
      new Date(2022, 6, 1),
    ]
    
  
    useEffect(
      () => {
        const intervalId = setInterval(() => {
        
          setTime(new Date());
        });
        return () => {
          clearInterval(intervalId)
        }
      } 
    )

    const msg = format(time, 'yyyy年MM月dd日HH:mm:ss')
    const convertMsToDays = ms => {
      const msInOneSecond = 1000
      const secondsInOneMinute = 60
      const minutesInOneHour = 60
      const hoursInOneDay = 24
    
      const minutesInOneDay = hoursInOneDay * minutesInOneHour
      const secondsInOneDay = secondsInOneMinute * minutesInOneDay
      const msInOneDay = msInOneSecond * secondsInOneDay
    
      return (ms / msInOneDay).toFixed(1)
    }
    
    const getDaysBetweenDates = (dateOne, dateTwo) => {
      let differenceInMs = dateTwo.getTime() - dateOne.getTime()
    
      if (differenceInMs < 0) {
        differenceInMs = dateOne.getTime() - dateTwo.getTime()
      }
    
      return convertMsToDays(differenceInMs)
    }

    const getSecondsBetweenDates = (dateOne, dateTwo) => {
      let differenceInMs = dateTwo.getTime() - dateOne.getTime()
    
      if (differenceInMs < 0) {
        differenceInMs = dateOne.getTime() - dateTwo.getTime()
      }
    
      return Math.ceil(differenceInMs / 1000)
    }
    
  
    return(
      <div className="time">
        <p> 现在是{msg} </p>
        
        你在奥克兰的回忆已经过去了{getDaysBetweenDates(time,dates[0])}天😀 - 或者{getSecondsBetweenDates(time,dates[0])}秒
        <br />
        
      
        希望在不久的将来我们能再见
      </div>
    )
  
  }
import Time from "./showTime";
import "./page.css";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  FadeOut,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  StickyOut,
  Zoom,
  ZoomIn,
  ZoomOut,
} from "react-scroll-motion";
import { Chrono } from "react-chrono";
import comment from "./images/comment.jpg"
import {useState, useRef} from "react"


export default function Home() {
  // Batch animations
  const [code,updateCode] = useState(123)
  const inputRef = useRef()
 
  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  const FadeUp = batch(Fade(), Move(), Sticky());
  const items = [
    {
      title: "26th June 2022",
      cardTitle: "Jo's Birthday",
      cardDetailedText:
        "我们熬夜到五点多，只为鉴赏出色的国内影视作品。是兄弟就要一起去看逐梦演艺圈。",
      media: {
        name: "jo's birthday",
        source: {
          url: "https://i.ibb.co/QPvd4j7/jo.jpg",
        },
        type: "IMAGE",
      },
    },
    {
      title: "28th June 2022",
      cardTitle: "Road trip to Hamilton",
      cardDetailedText:
        "世界最高城汉密尔顿，那里有好喝的奶茶，偶尔抽象偶尔美丽的植物园还有激光雨。",
      media: {
        name: "hmt",
        source: {
          url: "https://i.ibb.co/YRbPqjv/hmt.jpg",
        },
        type: "IMAGE",
      },
    },
    {
      title: "29th June 2022",
      cardTitle: "剧本杀+聚点",
      cardDetailedText: "那一晚，我们都是孤勇者。谢谢你的烧烤。",
      media: {
        name: "bbq",
        source: {
          url: "https://i.ibb.co/1TTZzpf/bbq.jpg",
        },
        type: "IMAGE",
      },
    },
  ];
  const msg = "我发现这不是我第一次送别去加拿大的朋友了，在我初中的时候就有一个朋友去了Ontario。我另一个朋友的发小也在加拿大，感觉这确实是个人杰地灵的好地方。送别初中同学的时候远没有现在感觉这么唏嘘，可能是因为越长越大也越孤独越珍惜身边的人。我最喜欢的朋友不一定是认识时间最长的。希望未来有机会能再见，不论是你回国还是去哪里旅游，又或者是各种机缘巧合，我都希望我们和你能再见。"

  function handleSubmit(e){
    e.preventDefault()
    if(inputRef.current.value == "9527"){
      updateCode("9527")
    }else{
      alert("Ask me for the code");
    }
  }

  return (
    <>
    
      <ScrollContainer>
        <ScrollPage>
          <div className="cover-page">
            <div style={{ "text-align": "center" }}>
              <iframe
              
                style={{ marginTop: 200 }}
                frameBorder="no"
                marginWidth={0}
                marginHeight={0}
                width={300}
                height={86}
                src="https://music.163.com/outchain/player?type=2&id=1465990541&auto=1&height=66"
                allow="autoplay"/>
            </div>

            <Animator animation={batch(Fade(), Sticky())}>
              {/* <img src={"cover.jpg"} height={300}></img> */}

              <h1 className="title">二零二二年七月一日</h1>
              <h1 className="title">奥克兰 → 多伦多</h1>
            </Animator>
          </div>
        </ScrollPage>

        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -300))}>
            <span style={{ fontSize: 50 }}>
              <Time />
            </span>
          </Animator>
        </ScrollPage>
        {/* //======================== time line here===================== */}

        <div className="timeline">
          <h2 style={{"textAlign":"center"}}>最后几天，我们：</h2>
          <div style={{"textAlign":"center",fontSize:"10px"}}>沿边角拖动</div>
          <Chrono
            items={items}
            theme={{
              primary: "black",
              secondary: "white",

              cardForeColor: "black",
              titleColor: "black",
              titleColorActive: "black",
            }}
            fontSizes={{
              cardSubtitle: "1rem",
              cardText: "1.5rem",
              cardTitle: "1.5rem",
              title: "1.5rem",
            }}
            mode="VERTICAL_ALTERNATING"
          />
        </div>
        {/* //======================================== */}

        <ScrollPage>
          <div
          className="card"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              
              height:"100%"
            }}
          >
            
            <span style={{ fontSize: 40,color:"white" }}>
              <Animator animation={MoveOut(300, 0)}>
                “多听听半岛铁盒👋🏻”
              </Animator>
              <Animator animation={MoveOut(300, 0)}>“以后常联系”</Animator>
              <Animator animation={MoveOut(300, 0)}>“在国内好好玩”</Animator>

              <Animator animation={MoveOut(-300, 0)}>"Good bye ✋🏻"</Animator>
              <Animator animation={MoveOut(-300, 0)}>
                "It's nice meeting you"
              </Animator>
              <Animator animation={MoveOut(-300, 0)}>
                “不要忘了我们” 💛
              </Animator>
              
            </span>
          </div>
        </ScrollPage>
        <ScrollPage>
          
          <Animator animation={batch(Fade(), Sticky(),MoveIn(0,750))}>
          
          <br/>
            <span style={{ fontSize: 20 }}>
              KJ, Simon, Andrew, hhy, Chris, Richie, Philip, Lydia, Jo and
              others...
            </span>
            <br />
            <span style={{ fontSize: 40 }}>祝你：</span>
            <br />
            <span style={{ fontSize: 30 }}>一帆风顺，学业进步</span>
            <br/>
           
            <img style={{height:"15vw",minHeight:150}} src={comment}></img>
            <p style={{fontStyle:"Italic"}}>“来点烧照。”
            -- 西蒙·黄</p>
            
            
            
            <div class="fb-comments" data-href="https://yaohan.cyou/" data-width="" data-numposts="5"></div>
            
       
            
          </Animator>
          
          
          
          
        </ScrollPage>

      
      <footer>
        <div style={{ textAlign: "center",marginTop:"35vh"}}>
          <a
            style={{ "text-decoration": "none" }}
            href="https://github.com/KingJacM/hyao"
          >
            Github link
          </a>

          <p>© Made by Jack Ma 2022</p>
        </div>
      </footer>
       
        
        

      

      
      
        
      
        
      </ScrollContainer>
      
    </>
  );
}
